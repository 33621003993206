import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Layout, Supported } from 'components';

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      window.scrollTo(0, 0);
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  return (
    <Layout>
      <Supported />
      <Component key={router.route} {...pageProps} />
    </Layout>
  );
}

export default MyApp;
